const HOLDER_TYPES = ['holder', 'safeHolder'];

const RELATED_PARTY_TYPES = ['relatedParty'];

const EMPLOYEE_TYPES = ['employee'];
const CONTRACTOR_TYPES = ['contractor'];

export const RELEVANT_AFFILIATE_TYPES = [
  ...HOLDER_TYPES,
  ...RELATED_PARTY_TYPES,
  ...EMPLOYEE_TYPES,
  ...CONTRACTOR_TYPES,
];

export const COMPANY_AFFILIATE_REQUIREMENTS: Record<string, { affiliateTypes: string[] }> = {
  'due-to-from-decreased-from-bank': {
    affiliateTypes: HOLDER_TYPES,
  },
  'due-to-from-decreased-from-bank-car-loan-paid': {
    affiliateTypes: HOLDER_TYPES,
  },
  'due-to-from-increased-from-bank': {
    affiliateTypes: HOLDER_TYPES,
  },
  'partner-contribution-received-in-bank': {
    affiliateTypes: HOLDER_TYPES,
  },
  'preferred-stock-received-in-bank': {
    affiliateTypes: HOLDER_TYPES,
  },
  'safe-investment-payment-received-in-bank': {
    affiliateTypes: HOLDER_TYPES,
  },
  'owner-contribution-received-in-bank': {
    affiliateTypes: HOLDER_TYPES,
  },
  'paid-in-capital-contribution-received-in-bank': {
    affiliateTypes: HOLDER_TYPES,
  },
  'shareholder-contribution-received-in-bank': {
    affiliateTypes: HOLDER_TYPES,
  },
  'due-to-from-decreased-from-bank-reimbursement': {
    affiliateTypes: HOLDER_TYPES,
  },
  'due-to-from-increased-from-bank-reimbursement': {
    affiliateTypes: HOLDER_TYPES,
  },
  'related-party-non-interest-balance-increased-from-bank': {
    affiliateTypes: RELATED_PARTY_TYPES,
  },
  'related-party-non-interest-balance-decreased-from-bank': {
    affiliateTypes: RELATED_PARTY_TYPES,
  },
  'due-to-employee-increased-from-bank': {
    affiliateTypes: EMPLOYEE_TYPES,
  },
  'due-to-employee-decreased-from-bank': {
    affiliateTypes: EMPLOYEE_TYPES,
  },
  'due-to-contractor-decreased-from-bank': {
    affiliateTypes: CONTRACTOR_TYPES,
  },
  'due-to-contractor-increased-from-bank': {
    affiliateTypes: CONTRACTOR_TYPES,
  },
  'related-party-cla-investment-repaid': {
    affiliateTypes: RELATED_PARTY_TYPES,
  },
  'related-party-cla-investment-received': {
    affiliateTypes: RELATED_PARTY_TYPES,
  },
};

export const COMPANY_AFFILIATE_REQUIRED_BUSINESS_EVENTS = new Set([]);
